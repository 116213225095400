@import "~antd/dist/antd.css";
* {
  margin: 0;
  padding: 0;
  /* text-align: left; */
  box-sizing: border-box;
}
.ant-table-column-sorters {
  width: auto !important;
}
.clearfix:after {
  clear: both;
}
p {
  margin-bottom: 0;
}
#root {
  font-family: "PingFang SC", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1c1c1c;
  height: 100vh;
  background-color: #f4f4f4;
}
a {
  text-decoration: none;
}
.flex {
  display: flex;
}
.flex_center {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.flex_center_wrap {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.flex_around {
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  align-items: center;
}
.flex_around_wrap {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.flex_between {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
}
.flex_between_wrap {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.flex_start {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
}
.flex_column_between {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex_start_top {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex_end {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  align-items: center;
}
.flex_start_wrap {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
/* 表格公用的头部的搜索css */
.search {
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 20px;
}
.search .ant-select {
  width: 200px !important;
}
.search .ant-input {
  width: 200px;
}
.search .ant-picker {
  width: 400px;
}
.search .title {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 30px;
}
.search .ant-form-inline .ant-form-item {
  margin-bottom: 20px !important;
}
.search .el-form {
  text-align: left;
}
.tabContainer {
  padding: 20px;
  background-color: #ffffff;
}
.tabContainer .tabCon .ant-btn {
  height: auto !important;
  padding: 0px 2px !important;
}
.tabContainer .tabCon {
  padding: 0px 10px;
}
/* 有切换选项的头部 */
.selectTitle {
  margin-bottom: 30px;
}
.selectTitle .list {
  font-size: 18px;
  color: #787878;
  margin-right: 20px;
  cursor: pointer;
}
.selectTitle .line {
  width: 1px;
  height: 10px;
  margin-right: 20px;
  background-color: #d8d8d8;
}
.selectTitle .active {
  color: #1c1c1c;
  font-weight: 700;
}
/* 表格 */
.tabContainer .title {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 30px;
}
.tabContainer .pageCon {
  margin-top: 30px;
  padding: 0px 10px;
}
.tabContainer .pageCon .left {
  text-align: left;
}
.tabContainer .pageCon .right {
  text-align: right;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  text-align: left;
  z-index: 999;
}
.mask .maskContent {
  position: absolute;
  background: #fff;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mask .maskContent .closeImg {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.mask .maskContent .title {
  padding: 20px;
  font-size: 15px;
  border-bottom: 1px solid #d8d8d8;
}
.mask .gray {
  width: 200px;
  height: 40px;
  background-color: #f6f6f6;
  display: inline-block;
  padding: 0px 16px 0px 10px;
  line-height: 40px;
}
.mask .gray .el-input {
  width: 80px;
  height: 30px !important;
  padding: 0px !important;
  float: right;
}
.maskBottom {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
.loading {
  position: fixed;
  top: 0;
  left: 230px;
  width: calc(100vw - 230px);
  height: 100vh;
  text-align: left;
  z-index: 999;
}
.loading .loadingContent {
  position: absolute;
  background: #fff;
  font-size: 14px;
  left: 50%;
  top: 50%;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 150px;
  height: 160px;
  border-radius: 5px;
  text-align: center;
}
.loading .loadingContent img {
  width: 30px;
  height: 30px;
  margin-top: 43px;
  margin-bottom: 28px;
}
.sure {
  border: 1px solid #fa502d;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fa502d;
  color: #fff;
  width: 80px;
  line-height: 1;
  text-align: center;
  padding: 12px 20px;
  display: inline-block;
  font-size: 14px;
}
.cancel {
  border: 1px solid #dbdbdb;
  cursor: pointer;
  border-radius: 4px;
  background-color: #dbdbdb;
  color: #787878;
  height: 40px;
  width: 80px;
  line-height: 1;
  text-align: center;
  padding: 12px 20px;
  display: inline-block;
  font-size: 14px;
}
/* 表格头部的操作按钮 */
.tableDefault {
  width: 120px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}
.ant-btn[name="reset"] {
  width: 80px;
  background: #dbdbdb;
  color: #787878;
}
.ant-btn[name="reset"]:hover {
  background: #dbdbdb;
  color: #787878;
}
.ant-btn[name="reset"]:focus {
  background: #dbdbdb;
  color: #787878;
}
.ant-btn[name="save"] {
  background-color: #fa502d;
  color: #ffffff;
  width: 80px;
}
.ant-btn[name="save"]:hover {
  background-color: #fa502d;
  color: #ffffff;
  width: 80px;
}
.ant-btn[name="save"]:focus {
  background-color: #fa502d;
  color: #ffffff;
  width: 80px;
  border: none;
}
.searchBtn {
  border: 1px solid #313036;
  cursor: pointer;
  border-radius: 4px;
  background-color: #313036;
  color: #fff;
  width: 80px;
  line-height: 1;
  height: 40px;
  text-align: center;
  padding: 12px 20px;
  font-size: 14px;
  display: inline-block;
  margin-right: 15px;
}
.ant-table thead > tr > th,
.ant-table tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 16px !important;
}
.ant-switch-checked {
  background-color: #00b178 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
